<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import Input from '~/Components/ui/input/Input.vue';
import ContactAddress from '~/types/ContactAddress';

defineOptions({
  inheritAttrs: false
})
const props = defineProps<{
  modelValue?: ContactAddress
}>()
const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()
const modelAddress = useVModel(props, 'modelValue', emits, { passive: true })
</script>

<template>
  <template v-if="modelAddress">
    <Input type="text" :placeholder="$t('street_address_placeholder')" :modelValue="<string>modelAddress.streetAddress"
      @input="modelAddress.streetAddress = $event.target.value" />
    <div class="flex gap-4">
      <Input type="text" :placeholder="$t('postcode_placeholder')" :model-value="<string>modelAddress?.postcode"
        @input="modelAddress.postcode = $event.target.value" />
      <Input type="text" :placeholder="$t('city_placeholder')" :model-value="<string>modelAddress.city"
        @input="modelAddress.city = $event.target.value" />
    </div>
    <div class="flex gap-4">
      <Input type="text" :placeholder="$t('state_placeholder')" :model-value="<string>modelAddress.state"
        @input="modelAddress.state = $event.target.value" />
      <Input type="text" :placeholder="$t('country_placeholder')" :model-value="<string>modelAddress.country"
        @input="modelAddress.country = $event.target.value" />
    </div>
  </template>
</template>
