<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import { tryOnMounted } from '@vueuse/core';
import { compact, startCase } from 'lodash';
import { computed, ref } from 'vue';
import { organizations } from '~/api';
import { Card, CardContent } from '~/Components/ui/card';
import Separator from '~/Components/ui/separator/Separator.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import CenterWrap from '~/Components/Wrapper/CenterWrap.vue';
import PlaceholderLogo from '~/Icons/PlaceholderLogo.vue';
import { dateFromDateTimeZone } from '~/lib/utils';
import { useAuthSesion } from '~/stores/authSession';
import { useModuleApiStore } from '~/stores/moduleApi';
import { OrganizationsInvoicesShow } from '~/types/serializers';

const props = defineProps<{
  invoice: OrganizationsInvoicesShow
}>()

const amountWithCurrency = computed(() => `${props.invoice.currencyCode}${Number(props.invoice.amount).toFixed(2)}`)
const moduleApi = useModuleApiStore()
const organization = ref()
const userSession = useAuthSesion()

tryOnMounted(async () => {
  const result = await moduleApi.fetchUrl(organizations.show, {accept: 'json', version: usePage().version})
  organization.value = result.props.organization
})
</script>

<template>
  <CenterWrap>
    <PageHeading pageId="invoice" class="mt-8" hideDescription backButton />
    <Card>
      <CardContent class="p-12">
        <div class="grid grid-cols-2 mb-16">
          <div class="w-fit">
            <PlaceholderLogo />
          </div>
          <div class="flex flex-col items-end col-start-2 col-end-2">
            <span>{{ startCase(userSession.user?.name) }}</span>
            <span>{{ userSession.user?.phoneNumber }}</span>
            <span>{{ organization?.name }}</span>
          </div>
        </div>

        <div class="grid grid-cols-[2fr_minmax(0,140px)_minmax(0,140px)_1fr] gap-4 mb-14 ">
          <div>
            <h6>Billed To</h6>
            <div>
              <div>{{ invoice.customer.name }}</div>
              <div>{{ invoice.billingAddress.streetAddress }}</div>
              <div>{{ compact([invoice.billingAddress.postcode, invoice.billingAddress.city]).join(' ') }}</div>
              <div>{{ compact([invoice.billingAddress.state, invoice.billingAddress.country]).join(',') }}</div>
            </div>
          </div>

          <div class="grid">
            <div class="mb-5">
              <h6>Date of Issue</h6>
              <div>{{ dateFromDateTimeZone(invoice.issueDate) }}</div>
            </div>

            <div class="mb-5">
              <h6>Due Date</h6>
              <div>{{ dateFromDateTimeZone(invoice.dueDate) }}</div>
            </div>
          </div>

          <div class="grid">
            <div>
              <h6>Invoice Number</h6>
              <div>{{ invoice.number }}</div>
            </div>
            <div>
              <h6>Reference</h6>
              <div>{{ invoice.reference }}</div>
            </div>
          </div>

          <div class="flex flex-col items-end justify-start">
            <h6>Amount Due ({{ invoice.currencyCode }})</h6>
            <div class="text-3xl">{{ amountWithCurrency }}</div>
          </div>
        </div>

        <Separator class="h-1 mb-3 bg-slate-700" />

        <div class="grid grid-cols-[2fr_1fr_100px_1fr] mb-4">
          <h6>Description</h6>
          <h6 class="text-right">Price</h6>
          <h6 class="text-right">Quantity</h6>
          <h6 class="text-right">Line Total</h6>
        </div>

        <div v-for="item in invoice.items">
          <div class="grid grid-cols-[2fr_1fr_100px_1fr]">
            <div>
              <div>{{ item.name }}</div>
              <div>
                {{ item.description }}
              </div>
            </div>

            <div class="text-right">{{ Number(item.price).toFixed(2) }}</div>
            <div class="text-right">{{ item.quantity }}</div>
            <div class="text-right">{{ Number(item.totalAmount).toFixed(2) }}</div>
          </div>

          <Separator class="my-2" />
        </div>

        <div class="grid grid-cols-2 mt-20">
          <div class="grid col-start-2">
            <div>
              <div class="grid grid-cols-2">
                <div class="text-right">Subtotal</div>
                <div class="text-right">{{ Number(invoice.subTotalAmount).toFixed(2) }}</div>

                <div class="text-right">Total Discount Amount</div>
                <div class="text-right">{{ Number(invoice.totalDiscountAmount).toFixed(2) }}</div>
              </div>
              <Separator class="my-3" />
              <div class="grid grid-cols-2">
                <div class="text-right">Total</div>
                <div class="text-right">{{ Number(invoice.amount).toFixed(2) }}</div>
              </div>

              <div class="grid grid-cols-2 py-3 mt-6 border-t-4 border-double">
                <div class="text-right text-muted-foreground">Amount Due ({{ invoice.currencyCode }})</div>
                <div class="text-right">{{ amountWithCurrency }}</div>
              </div>

            </div>
          </div>
        </div>

        <div class="mt-20">
          <h6>Notes</h6>
          <div>Notes here</div>
        </div>

      </CardContent>
    </Card>
  </CenterWrap>
</template>
