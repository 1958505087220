import type { ColumnDef } from "@tanstack/vue-table";
import { ItemSchema } from "../types/itemSchema";
import { t } from "~/entrypoints/i18n";
import { isNil, snakeCase } from "lodash";
import { h } from "vue";
import { V8Icon } from "~/Components/icon";
import Input from "./Input.vue";
import { useInvoiceFormData } from "~/stores/invoiceFormData";
import { pinia } from "~/entrypoints/pinia";
import ItemsCombobox from "./ItemsCombobox.vue";
import TaxCombobox from "./taxCombobox.vue";
import { OrganizationsTaxesIndex } from "~/types/serializers";
import { emitter } from "~/entrypoints/emitter";
import { TAccount, TItemRow } from "../types/types";
import AccountsCombobox from "./AccountsCombobox.vue";
import { INVOICE_ITEM_SELECTION } from "~/shared/eventTypeKeys";

const storeFormInvoice = useInvoiceFormData(pinia)

export const itemColumns: ColumnDef<ItemSchema>[] = [
  {
    accessorKey: "name",
    header: () => t("item_name"),
    cell: ({ row, column }) => {
      const itemKey = column.id as keyof typeof row.original;

      if (!row.original) return;

      return h(ItemsCombobox, {
        modelValue: row.original[itemKey] as string,
        "onUpdate:item": (value: ItemSchema) => {

          // console.log("selectedItem: ", value);

          emitter.emit(INVOICE_ITEM_SELECTION, {
            rowIndex: row.index,
            item: value,
          } as TItemRow);
        },
      });
    },
  },

  {
    accessorKey: "description",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: ({ row, column }) => {
      type ItemKey = keyof typeof row.original;
      const itemKey: ItemKey = column.id as ItemKey;

      if (!row.original) return;

      return h(Input, {
        placeholder: t("item_description"),
        modelValue: row.original[itemKey] as string,
        onFocus: ($event: any) => $event.target.select(),
      });
    },
  },

  {
    accessorKey: "accountId",
    header: () => t("account"),
    cell: ({ row }) => {
      if (!row.original) return;

      const itemKey = "accountId" as keyof typeof row.original;
      // NOTE: Note that Server also send 'account' in edit mode.

      const isEditMode = !isNil(row.original.id); // item has ID
      const account: TAccount | null | undefined = isEditMode ? row.original.account : undefined

      return h(AccountsCombobox, {
        account,
        modelValue: row.original[itemKey] as number
      });
    },
  },

  {
    accessorKey: "quantity",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: ({ row, column }) => {
      const itemKey = column.id as keyof typeof row.original;

      if (!row.original) return;

      return h(Input, {
        type: "number",
        placeholder: "0",
        modelValue: row.original[itemKey] as number,
        onFocus: ($event: any) => $event.target.select(),
      });
    },
  },

  {
    accessorKey: "price",
    header: () => t("price"),
    cell: ({ row }) => {
      if (!row.original) return;
      // get price
      const price = row.original.price;

      return h(Input, {
        type: "number",
        step: "0.01",
        min: 0,
        placeholder: 5.29,
        modelValue: Number(price).toFixed(2),
        onFocus: ($event: any) => $event.target.select(),
      });
    },
  },

  {
    accessorKey: "discountAmount",
    header: ({ column }) => t(snakeCase(column.id)),
    cell: ({ row, column }) => {
      const itemKey = column.id as keyof typeof row.original;
      if (!row.original) return;

      return h(Input, {
        type: "number",
        step: "0.01",
        modelValue: Number(row.original[itemKey]).toFixed(2),
        onFocus: ($event: any) => $event.target.select(),
      });
    },
  },

  {
    accessorKey: "Amount",
    header: () => t("amount"),
    cell: ({ row }) => {
      if (!row.original) return;

      let amount = 0;

      const price = Number(row.original.price);
      const quantity = Number(row.original.quantity);

      if (!Number.isNaN(price) && !Number.isNaN(quantity))
        amount = price * quantity;

      return amount.toFixed(2);
    },
  },

  {
    accessorKey: "taxId",
    header: () => t("tax"),
    cell: ({ row }) => {
      if (!row.original) return;

      return h(TaxCombobox, {
        modelValue: row.original.taxId as number,
        "onUpdate:taxItem": (value: OrganizationsTaxesIndex) => {
          // to use the tax values
          // console.log("value", value);
          storeFormInvoice.updateTaxForItem(row.index, value);
        },
      });
    },
  },

  {
    id: "actions",
    enableResizing: false,
    meta: {
      attributes: {
        class: "w-10",
      },
    },
    cell: ({ row }) => {
      return h(
        "button",
        {
          type: "button",
          class: "py-1 pl-2 pe-3 end-0 text-muted-foreground",
          onClick: (_event) => {
            console.log("onClick");
            emitter.emit("remove-item", {
              rowIndex: row.index,
              item: row.original,
            });
          },
        },
        h(V8Icon, {
          icon: "lucide:x",
          class: "w-4 h-4",
        })
      );
    },
  },
];
