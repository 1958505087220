import { z } from "zod";

export const itemSchema = z
  .object({
    id: z.number().optional().nullable(),
    price: z.number().or(z.string()).optional(), // server return string so add Union to string
    taxId: z.number().optional().nullable(),
    name: z.string().nullable(),
    description: z.string().optional().nullable(),
    quantity: z.number(),
    // uomName: z.string(),
    uomId: z.number().optional().nullable(),
    itemId: z.number().optional().nullable(),
    discountAmount: z.number().or(z.string()),
    accountId: z.number(),
    account: z.object({
      id: z.number(),
      code: z.string(),
      name: z.string()
    }).optional().nullable()
    // taxPercentage: z.string()
  })
  .nullable();

export type ItemSchema = z.infer<typeof itemSchema>;

export const itemInitialValue: ItemSchema = {
  id: null,
  description: null,
  discountAmount: "0.0",
  itemId: null,
  name: null,
  price: "0.0",
  quantity: 0,
  taxId: null,
  uomId: null,
  accountId: 0
};
