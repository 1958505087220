import { z } from "zod";
import { itemSchema } from "../types/itemSchema";

const addressSchema = z.object({
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  postcode: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  streetAddress: z.string().optional().nullable(),
});

export const invoiceFormSchema = z.object({
  // exists in edit mode
  id: z.number().optional().nullable(),
  editable: z.boolean().optional(),

  // for show only (extracted from show serializer)
  // amount: z.string().optional(),
  // payableAmount: z.number().or(z.string()).optional(), // server sent as string
  // subTotalAmount: z.number().or(z.string()).optional(), // server sent as string
  // totalDiscountAmount: z.number().or(z.string()).optional(), // server sent as string
  // totalTaxAmount: z.number().or(z.string()).optional(), // server sent as string
  //- exists in edit mode

  customerId: z.number(),
  dueDate: z.string(),
  issueDate: z.string(),
  number: z.string(),
  reference: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  currencyCode: z.string(),
  taxType: z.string(),
  status: z.string(),
  items: z.array(itemSchema).optional().nullable(),
  billingAddress: addressSchema.optional().nullable(),
  shippingAddress: addressSchema.optional().nullable(),
});

export type TInvoiceFormSchema = z.infer<typeof invoiceFormSchema>;
