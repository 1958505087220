<script setup lang="ts">
import { FlexRender, getCoreRowModel, Row, RowData, useVueTable } from '@tanstack/vue-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/Components/ui/table';
import { itemColumns } from './itemColumns';
import { tryOnMounted, useVModel } from '@vueuse/core';
import type { ItemSchema } from '~/pages/Organizations/Invoices/types/itemSchema';
import '@tanstack/vue-table'
import { useModuleApiStore } from '~/stores/moduleApi';
import { organizationsAccounts, organizationsTaxes } from '~/api';
import { provide, ref } from 'vue';
import { OrganizationsAccountsShow, OrganizationsTaxesIndex } from '~/types/serializers';
import Button from '~/Components/ui/button/Button.vue';
import { itemInitialValue } from '~/pages/Organizations/Invoices/types/itemSchema';
import { PlusIcon } from 'lucide-vue-next';
import { emitter } from '~/entrypoints/emitter';
import { TItemRow } from '../types/types';
import { removeBlankItem } from '~/lib/utils';
import { isNil, merge, pick } from 'lodash';
import { INVOICE_ITEM_SELECTION } from '~/shared/eventTypeKeys';

declare module '@tanstack/vue-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    attributes: any
  }
}

// NOTE: Need to this to have control on events
// or otherwise, inherited events from parent (vue-validate)
defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  modelValue: ItemSchema[],
  initialValue?: ItemSchema[]
}>()

interface IEmits {
  (e: 'update:modelValue', payload: any): void
  (e: 'update:open', payload: any): void
  (e: 'update:initialValue', payload: any): void
}

const emit = defineEmits<IEmits>()
const data = useVModel(props, 'modelValue', emit, { passive: true, deep: true })
const table = useVueTable({
  get data() { return data.value },
  get columns() { return itemColumns },
  getCoreRowModel: getCoreRowModel()
})

const taxList = ref<OrganizationsTaxesIndex[]>()
const accountList = ref<OrganizationsAccountsShow[]>()
const moduleApi = useModuleApiStore()

const updateModel = (value: string | number, key: string, row: Row<ItemSchema>) => {
  // console.log('receive updatemodel', value, key)
  if (!data.value) return

  const item = data.value.at(row.index) // data.value instead of props.modelValue (untrackable)
  const theKey = key as keyof typeof row.original
  if (item) {
    const thatItem = item[theKey]
    type TItemType = typeof thatItem
    item[theKey] = value as TItemType // useVModel same as emit('update:modelValue', value)
  }
}

function updateDefaultValue(value: string | number, key: string, row: Row<ItemSchema>) {
  const isEditMode = !isNil(row.original?.id); // item has ID
  !isEditMode && updateModel(value, key, row)
}

const addItem = () => {
  data.value = [...data.value, itemInitialValue]
}
emitter.on('remove-item', (event) => {
  const itemRow = event as TItemRow
  data.value = data.value.toSpliced(itemRow.rowIndex, 1)
})
emitter.on(INVOICE_ITEM_SELECTION, (event: any) => {
  const itemRow = event as TItemRow
  const itemAtIndex = data.value.at(itemRow.rowIndex)

  // console.log('currenctRowIsEditing', itemRow.rowIndex)
  // console.log("\t found item", itemAtIndex)
  if (itemAtIndex) {
    // use selected item, but retain some property: (accountId)
    if (itemRow.item) {
      itemRow.item = merge({}, itemRow.item, pick(itemAtIndex, ['account', 'accountId']))
    }

    const replaced = data.value.toSpliced(itemRow.rowIndex, 1, itemRow.item)
    data.value = removeBlankItem(replaced)
  }
})

tryOnMounted(() => {
  loadTaxes()
  loadAccounts()
})

async function loadTaxes() {
  const response = await moduleApi.gotoIndex(organizationsTaxes.index, { accept: 'json' })
  taxList.value = response.taxes
}
async function loadAccounts() {
  const response = await moduleApi.fetchUrl(organizationsAccounts.index, { accept: 'json' })
  accountList.value = response.props.accounts
}
provide('taxList', { taxList })
provide('accountList', {
  accounts: computed(() => accountList.value)
})

</script>

<template>
  <div class="border rounded-md">
    <Table>
      <TableHeader>
        <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
          <TableHead v-for="header in headerGroup.headers" :key="header.id"
            v-bind="header.column.columnDef.meta?.attributes">
            <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header"
              :props="header.getContext()" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <template v-if="table.getRowModel().rows?.length">
          <TableRow v-for="row in table.getRowModel().rows" :key="row.id">
            <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
                :modelValue="modelValue"
                @update:modelValue="(value) => updateModel(value, cell.column.id, row)"
                @update:defaultValue="(value) => updateDefaultValue(value, cell.column.id, row)"
              />
            </TableCell>
          </TableRow>
        </template>
        <TableRow v-else>
          <TableCell :colspan="itemColumns.length" class="h-24 text-center">
            {{ $t('table.no_result') }}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>

  <div class="flex">
    <Button
      @click="addItem"
      variant="ghost"
      type="button"
      class="w-full border-2 border-dashed cursor-pointer border-zinc-400 hover:border-zinc-500 group"
    >
      <PlusIcon strokeWidth="3" class="mr-4 text-gray-400 group-hover:text-gray-600" /> <span class="text-lg">Add a
        Line</span>
    </Button>
  </div>
</template>
