<script setup lang="ts">
import { ref } from 'vue';
import { Popover, PopoverContent, PopoverTrigger } from '~/Components/ui/popover';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '~/Components/ui/command';
import { createReusableTemplate, tryOnMounted, useVModel } from '@vueuse/core';
import { useModuleApiStore } from '~/stores/moduleApi';
import { organizationsAccounts } from '~/api';
import { usePage } from '@inertiajs/vue3';
import { OrganizationsAccountsShow } from '~/types/serializers';
import { Badge } from '~/Components/ui/badge'
import { V8Icon } from '~/Components/icon';
import { TAccount } from '../types/types';
import { isNil } from 'lodash';
import { t } from '~/entrypoints/i18n';

const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)

defineOptions({ inheritAttrs: false })

const props = defineProps < {
  modelValue?: number | string
  defaultValue?: number | string
  account?: TAccount | null
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: number | string): void
  (e: 'update:defaultValue', payload: number | string): void
}>()

const [UseTemplate, StatusList] = createReusableTemplate()
const [AccountInfoTpl, ReUseAccountInfo] = createReusableTemplate()
const modelValue = useVModel(props, 'modelValue', emits, { passive: true })
const isOpen = ref(false)
const items = ref<OrganizationsAccountsShow[]>()
const selectedItem = ref<OrganizationsAccountsShow | TAccount | null>(null)
const accountInjected = inject('accountList')

watch(() => accountInjected, (o: any) => {
  items.value = o.accounts.value
}, {
  deep: true
})

/**
  onLoad, load Account list
 */
tryOnMounted(async () => {
  // const response = await moduleApi.fetchUrl(organizationsAccounts.index, { accept: 'json' })
  // items.value = response.props.accounts

  // const response = await moduleApi.fetchUrl(organizationsAccounts.index, { accept: 'json' })
  // items.value = response.props.accounts

  if (isNil(props.account)) {
    setDefaultAccount()
  } else {
    selectedItem.value = props.account
  }
})

/**
 * 1. set default account (take first item in array) - and notify parent
 */
function setDefaultAccount() {
  if (!items.value) return

  // set first default selection to first value
  selectedItem.value = items.value[0]
  // set default accountId
  if (selectedItem.value?.id) {
    emits('update:defaultValue', selectedItem.value.id)
  }
}

function onItemSelect(o: OrganizationsAccountsShow) {
  modelValue.value = o.id
  // NOTE: What is examined here is that
  // 1. useVModel const will not reflect here event reassigned; `.value = something'
  //    As it use-case, assign it will internally, run 'emit()' to 'account'
  // 2. props.account is read only. can't reassign
  // 3. for that matter, need to create another ref just to store selected item
  selectedItem.value = o
  isOpen.value = false
}
</script>

<template>
  <AccountInfoTpl v-slot="{ code, name }">
    <div class="w-full">
      <Badge variant="outline" class="text-[10px] py-0 px-1 mr-1 rounded">{{ code }}</Badge>{{ name }}
    </div>
  </AccountInfoTpl>
  <Popover v-model:open="isOpen">
    <PopoverTrigger class="flex items-center gap-2">
      <ReUseAccountInfo :code="selectedItem.code" :name="selectedItem.name" v-if="selectedItem" />
      <V8Icon icon="mdi:chevron-down" width="2rem" class="text-slate-500" />
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0" align="start">
      <StatusList />
    </PopoverContent>
  </Popover>

  <UseTemplate>
    <Command>
      <CommandInput placeholder="Filter status..." />
      <CommandList>
        <CommandEmpty>{{ t('no_result_found') }}</CommandEmpty>
        <CommandGroup>
          <CommandItem v-for="item of items" :key="item.id" :value="item.code || ''" @select="onItemSelect(item)"
            v-if="items">
            <ReUseAccountInfo :code="item.code" :name="item.name" />
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  </UseTemplate>
</template>
