import { Method, PathHelper } from "@js-from-routes/client";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { router } from "@inertiajs/vue3";
import { merge } from "lodash";
import { crash } from "~/lib/utils";

export const useModuleApiStore = defineStore('moduleApi', () => {
  const indexPath = ref<PathHelper>();
  const newPath = ref<PathHelper>();
  const createPath = ref<PathHelper>();
  const editPath = ref<PathHelper>();
  const updatePath = ref<PathHelper>();
  const destroyPath = ref<PathHelper>();
  const showPath = ref<PathHelper>();
  const activeRecord = ref();
  const currentEditRecord = ref();
  const responseProps = ref();

  interface IEditOptions {
    sideAction?: () => void;
    accept?: "json";
    expectedKey?: string;
  }

  interface IUrlOptions extends IEditOptions {
    params?: {};
    method?: Method;
    version?: string | null;
    data?: Record<string, unknown>;
  }

  let indexOptions: IEditOptions | undefined;
  let newOptions: IEditOptions | undefined;
  let editOptions: IEditOptions | undefined;
  let showOptions: IEditOptions | undefined;

  // https://github.com/inertiajs/inertia-laravel/issues/103#issuecomment-1116846463
  // https://gist.github.com/mohitmamoria/91da6f30d9610b211248225c9e52bebe
  interface IHeaders {
    "X-Inertia": boolean;
    "X-Inertia-Version": string | null;
  }
  const headers: IHeaders = reactive({
    "X-Inertia": true,
    "X-Inertia-Version": null
  });

  function validateHeaders() {
    if (!headers["X-Inertia-Version"]) {
      throw "Version not set. Need to set explicitly if accepting as json."
    }
  }

  const isModeEdit = computed(
    () => currentEditRecord.value && currentEditRecord.value.id
  );

  function preRun() {
    activeRecord.value = null
    responseProps.value = null
    currentEditRecord.value = null
  }

  function setVersion(version: string | null) {
    preRun();
    headers["X-Inertia-Version"] = version;
  }

  function setIndex(indexPathHelper: PathHelper | any, options?: IEditOptions) {
    indexOptions = options;

    indexPath.value = indexPathHelper;
  }

  function setNew(newPathHelper: PathHelper | any, options?: IEditOptions) {
    newOptions = options;

    newPath.value = newPathHelper;
  }

  function setCreate(createPathHelper: PathHelper) {
    createPath.value = createPathHelper
  }

  function setEdit(editPathHelper: PathHelper, options?: IEditOptions) {
    editOptions = options;

    editPath.value = editPathHelper;
  }

  function setUpdate(updatePathHelper: PathHelper) {
    updatePath.value = updatePathHelper;
  }

  function setShow(showPathHelper: PathHelper | any, options?: IEditOptions) {
    showOptions = options;

    showPath.value = showPathHelper;
  }

  function setDestroy(destroyPathHelper: PathHelper) {
    destroyPath.value = destroyPathHelper;
  }

  async function gotoUrl(
    pathHelper: PathHelper,
    options: IUrlOptions
  ) {
    let params = {};
    let method = 'get'

    if (options.params) {
      params = options.params;
    }

    if (options.method) {
      method = options.method
    }

    if (options.accept === "json") {
      // console.log("[JS_from_route:axios] goto...", pathHelper.path(params));
      validateHeaders();
      const response = await pathHelper({ headers });
      return response.props;
    }

    console.log("[InertiaJS] goto...");

    const context = {
      get: router.get,
      patch: router.patch
    };

    type TMethod = keyof typeof context

    return context[method as TMethod].apply(router, [pathHelper.path(params)]);
    // if (method === 'patch')
    //   return router.patch(pathHelper.path(params));
    // return router.get(pathHelper.path(params));
  }

  async function gotoIndex(
    indexPathHelper?: PathHelper | any,
    options?: IEditOptions
  ) {

    if (indexPathHelper && options)
      return gotoUrl(indexPathHelper, options)

    if (!indexPath.value) {
      console.log("indexPath is not set");
      return;
    }

    if (!indexOptions) {
      // console.log("[InertiaJS] routing...");
      return router.get(indexPath.value.path());
    }

    if (indexOptions.accept === "json") {
      // console.log("[JS_from_route:axios] routing...", indexPath.value.path());
      validateHeaders();
      const response = await indexPath.value({ headers });
      return response.props;
    }
  }

  async function gotoNew() {
    if (!newPath.value) {
      console.log("newPath is not set");
      return;
    }

    preRun();

    if (typeof newPath.value.path !== "function") {
      return newPath.value.call(null);
    }

    if (!newOptions) {
      console.log("[InertiaJS] routing...");
      return router.get(newPath.value.path());
    }

    if (!newOptions.expectedKey) {
      console.log("missing expectedKey");
      return;
    }

    if (newOptions.accept === "json") {
      console.log("[JS_from_route:axios] routing...");
      validateHeaders()
      const response = await newPath.value({ headers });
      responseProps.value = response.props;
    }

    if (newOptions.sideAction) {
      newOptions.sideAction.call(null);
    }
  }

  function gotoCreate(form: any) {
    console.log('createPath.value', createPath.value)
    if (createPath.value) router.post(createPath.value.path(), form);
  }

  async function gotoEdit(id: string) {
    if (!editPath.value) {
      console.log("editPath is not set");
      return;
    }

    if (!editOptions) {
      // console.log('[InertiaJS] routing...')
      return router.get(editPath.value.path({ id: id }));
    }

    if (!editOptions.expectedKey) {
      console.log('missing expectedKey')
      return
    }

    if (editOptions.accept === "json") {
      console.log("[JS_from_route:axios] routing...", editPath.value.path({ id: id }));
      validateHeaders();
      const response = await editPath.value({ id: id, headers });
      currentEditRecord.value = response.props[editOptions.expectedKey];
      // TODO: if key id not exist, add one with 'id' value

      responseProps.value = response.props
    }

    if (editOptions.sideAction) {
      editOptions.sideAction.call(null)
    }
  }

  function gotoUpdate(params: {}, id?: any, updatePathHelper?: PathHelper) {
    let theUpdatePath = updatePathHelper;

    if (!updatePath.value && !theUpdatePath) {
      console.log("updatePath is not set");
      return;
    }

    theUpdatePath = updatePathHelper ?? updatePath.value;
    if (!theUpdatePath) return

    let editId

    if (id)
      editId = id;
    else if (currentEditRecord.value)
      editId = currentEditRecord.value.id;
    else {
      console.log('id is not given and/or record to update was not set')
      return;
    }

    router.patch(theUpdatePath.path({ id: editId }), params);
  }

  function gotoDelete(id: string) {
    if (destroyPath.value) {
      const destroyUrl = destroyPath.value.path({ id: id });
      router.delete(destroyUrl);
      activeRecord.value = null
    }
  }

  function gotoShow(id: number | string) {
    if (!showPath.value) {
      console.log("showPath is not set");
      return;
    }

    if (!showOptions) {
      console.log("[InertiaJS] routing...");
      return router.get(showPath.value.path({ id: id }));
    }

    if (showOptions.accept === "json") {
      // console.log("[JS_from_route:axios] routing...");
      validateHeaders();
      return showPath.value({ id: id, headers });
    }
  }

  type TApiMethods = {
    archive: PathHelper,
    index: PathHelper,
    create: PathHelper,
    new: PathHelper,
    edit: PathHelper,
    show: PathHelper,
    update: PathHelper,
    destroy: PathHelper,
  }

  type POption = {
    params: {} | undefined;
    method: string | "get" | undefined;
    accept: "json" | undefined;
    data: Record<string, unknown>;
    version: string | null | undefined;
  };

  const apis = ref<{[key: string]: unknown}>({})
  function initCollectionFor(id: string, pathHelper: TApiMethods) {
    return apis.value[id] = {
      pathHelper: pathHelper,
      // fetchArchive:    fetchUrlHelper(),
      fetchIndex: (options: IUrlOptions) => fetchUrlHelper(pathHelper.index, options),
      fetchCreate: (data: object, options?: IUrlOptions) => fetchUrlHelper(pathHelper.create, merge(options, {data})),
      // fetchNew:        fetchUrlHelper(pathHelper.new),
      // fetchEdit:       fetchUrlHelper(pathHelper.edit),
      // fetchShow:       fetchUrlHelper(pathHelper.show),
      fetchUpdate: (data: object, options?: IUrlOptions) => {
        options?.params || crash("Params is not set");
        return fetchUrlHelper(pathHelper.update, merge(options, {data}))
      },
      // fetchDestroy:    fetchUrlHelper(pathHelper.destroy),
    };
  }

  function getApi(id: string) {
    return apis.value[id]
  }

  function fetchUrlHelper(pathHelper: PathHelper, options?: IUrlOptions) {
    const { params, accept, data, version } = processOptions(options);

    if (accept === "json") {
      version && setVersion(version);
      validateHeaders();
      // TODO data, params, options
      return pathHelper({ params, headers });
    }

    type TRouterMethodKey = 'get' | 'post' | 'put' | 'patch' | 'delete';
    const pathHelperMethod = pathHelper.httpMethod.toLowerCase();
    const routerMethod = router[pathHelperMethod as TRouterMethodKey].bind(router);

    console.log('params', params)
    return routerMethod(pathHelper.path(params), data as any);
  }

  function processOptions(options: IUrlOptions | undefined): POption {
    // console.log('optons', options)
    return {
      params: options?.params,
      method: options?.method?.toLowerCase() ?? "get",
      accept: options?.accept,
      version: options?.version,
      data: options?.data ?? {}
    };
  }

  return {
    activeRecord,
    currentEditRecord,
    responseProps,
    apis,

    indexPath,
    newPath,
    createPath,
    editPath,
    updatePath,
    showPath,
    destroyPath,

    initCollectionFor,
    getApi,

    setVersion,
    setIndex,
    setNew,
    setCreate,
    setEdit,
    setUpdate,
    setShow,
    setDestroy,

    gotoIndex,
    gotoNew,
    gotoCreate,
    gotoEdit,
    gotoUpdate,
    gotoShow,
    gotoDelete,
    gotoUrl,
    fetchUrl: fetchUrlHelper,

    isModeEdit,
  };
})
