import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'
import { t } from '~/entrypoints/i18n'
import { Checkbox } from '~/Components/ui/checkbox'

import type { InvoiceSchema } from './schema'
import DataTableColumnHeader from '~/Components/v8/DataTable/DataTableColumnHeader.vue'
import DataTableRowActions from '~/Components/v8/DataTable/DataTableRowActions.vue'

export const columns: ColumnDef<InvoiceSchema>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'number',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('number') }),
    cell: ({ row }) => {
      return h("div", { class: "w-20" }, row.original.number);
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'customer',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('customer') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.original.customer.name),
      ])
    },
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('amount') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'font-medium' }, Number(row.getValue('amount')).toFixed(2)),
      ])
    },
  },
  {
    accessorKey: 'issueDate',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('creation_date') }),

    cell: ({ row }) => {
      return h("div", { class: "flex space-x-2" }, [
        h("span", { class: "font-medium" }, row.getValue("issueDate")),
      ]);
    },
  },
  {
    accessorKey: 'dueDate',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('due_date') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'font-medium' }, row.getValue('dueDate')),
      ])
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('status') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'font-medium' }, row.getValue('status')),
      ])
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row, isNotEditable: !row.original.editable }),
  },
]
